/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@font-face {
  font-family: 'SFProDisplay';
  src:
    url(../../taf-dok/resources/fonts/FontsFree-Net-SFProDisplay-Regular-1.ttf) format("truetype");
  font-style: normal ;
  font-weight: 400;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url(../../taf-dok/resources/fonts/FontsFree-Net-SFProDisplay-Medium.ttf) format("truetype");
  font-style: normal ;
  font-weight: 500;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url(../../taf-dok/resources/fonts/FontsFree-Net-SFProDisplay-Black.ttf) format("truetype");
  font-style: normal ;
  font-weight: 800;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url(../../taf-dok/resources/fonts/FontsFree-Net-SFProDisplay-Light-1.ttf) format("truetype");
  font-style: normal ;
  font-weight: 300;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url(../../taf-dok/resources/fonts/FontsFree-Net-SFProDisplay-Thin.ttf) format("truetype");
  font-style: normal ;
  font-weight: 200;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url(../../taf-dok/resources/fonts/FontsFree-Net-SFProDisplay-Ultralight.ttf) format("truetype");
  font-style: normal ;
  font-weight: 100;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url(../../taf-dok/resources/fonts/FontsFree-Net-SFProDisplay-Semibold.ttf) format("truetype");
  font-style: normal ;
  font-weight: 600;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url(../../taf-dok/resources/fonts/FontsFree-Net-SFProDisplay-Heavy-1.ttf) format("truetype");
  font-style: normal ;
  font-weight: 700;
}

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  font-family: 'SFProDisplay', sans-serif;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

ion-toolbar {
  --background: rgb(0, 62, 87);
  --color: #fff;

  ion-back-button {
    --color: #fff;
  }
}

ion-accordion.accordion-collapsed ion-item[slot="header"],
ion-accordion.accordion-collapsing ion-item[slot="header"],
ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --background: rgb(244, 245, 248);
  --ion-item-color: rgb(0, 62, 87);
}

ion-accordion.accordion-collapsed[slot="content"],
ion-accordion.accordion-collapsing [slot="content"],
ion-accordion.accordion-expanding [slot="content"],
ion-accordion.accordion-expanded [slot="content"] {
  --ion-item-background:  rgb(244, 245, 248);
  --ion-item-color: rgb(0, 62, 87);
}

ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      color: rgb(0, 62, 87);
    }
  }
}

ion-tab-bar {
  background-color: rgb(235, 235, 235);
  border-radius: 8px;
  margin-bottom: 5px;

  ion-tab-button {
    background-color: rgb(235, 235, 235);
  }
}

.chat {
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 8px;
}

ion-button.chat-button {
  --background:rgb(0, 62, 87);
  --box-shadow: none;
}

ion-button.share-button {
  --background:rgb(0, 62, 87);
  --box-shadow: none;
}

ion-segment {
  padding: 5px;
  --background: rgb(244, 245, 248);
}

ion-segment-button {
  padding:5px;
}

ion-segment-button {
  .button-native {
    padding:5px;
    background-color:  rgb(204, 204, 204);
  }
}

ion-button.filter {
  --background:  rgb(204, 204, 204);
}

.filter {
  color: rgb(0, 62, 87);
}

ion-button.filter-button2 {
  --background: rgb(204, 204, 204);
  --color: rgb(0, 62, 87);
}

.arrow {
  color:rgb(0, 62, 87);
  height: 50px;
  width: 35px;
}

ion-searchbar {
  --color:rgb(0, 62, 87);
  --background: rgb(0, 62, 87);
}

ion-searchbar {
  .searchbar-input-container {
    color: rgb(0, 62, 87);
    background-color: rgb(0, 62, 87);

  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

ion-label {
  --color: rgb(0, 62, 87);
}

.no-signature .alert-wrapper {
  --background: #ffff;
  min-width: fit-content;
  border-radius: 10px;
  .alert-head{
    background: rgb(0, 62, 87);
    border-radius: 10px 10px 0 0;
    h2{
      color: #fff;
    }
  };
  .alert-radio-label.sc-ion-alert-md {
    padding-inline-start: 8%;
  }
  .alert-radio-label{
    color: rgb(0, 62, 87);
  }
  [aria-checked=true].sc-ion-alert-md.alert-radio-label.sc-ion-alert-md{
    --color: rgb(0, 62, 87);
  }
}

.w-100 {
  width: 100%;
}

.menu {
  background-color: unset;
}

hr {
  margin: 0;
}
